<template>
  <div class="row justify-content-center">
    <div class="col-11 col-md-6 mb-3">
      <SelectDatePicker
        id="selectdate"
        @apply="setDate"
        label="Zakaah Calculation Date:"
        v-model:modelValue="selectedDate"
      />
    </div>
    <div class="col-11 col-md-6 mb-3">
      <TextField type="text" color="gold" placeholder="Islamic Date" label="Zakaah Calculation Date:" v-model="islamicDateFormat" :disabled="true" id="zakaahIslamicDate"  />
      <div class="disclaimer px-2">
        *Disclaimer: The Islamic date could be 1 day ahead or behind due to regional differences.
      </div>
    </div>
    <transition name="fade">
      <Alert :title="alert.title" :message="alert.message" v-if="alert !== null" @close="closeAlert" :align="alert.align" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    SelectDatePicker: defineAsyncComponent(() => import('@/components/datepicker/DatePickerSelect.vue')),
    Alert: defineAsyncComponent(() => import('./Alert.vue'))
  },
  name: 'Zakaah Set Date',
  data () {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD'
      },
      today: null,
      selectedDate: '',
      islamicDate: '',
      dateToConvert: '',
      alert: null
    }
  },
  beforeMount () {
    this.today = DateTime.now().toISODate()
    this.selectedDate = DateTime.now().toISODate()
  },
  watch: {
    selectedDate (val) {
      this.updateDate(val)
    }
  },
  methods: {
    ...mapActions([
      'setExchangeRate',
      'setGoldSpotPricesPerOunce',
      'setSilverSpotPricesPerOunce',
      'setGoldSpotPricesPerGram',
      'setSilverSpotPricesPerGram',
      'setGregorianToHijri'
    ]),
    setDate (val) {
      this.selectedDate = val
    },
    async updateDate (val) {
      console.log('VAL', val)
      if (DateTime.fromISO(val) > DateTime.fromISO(this.today)) {
        this.openAlert('Invalid Date Selection', `Unable To Fetch Spot Prices for ${this.selectedDate}.<br><br>Using Spot Prices For Today`)
        this.selectedDate = this.today
      } else {
        this.dateToConvert = DateTime.fromISO(val).toFormat('dd-MM-yyyy')
        await this.getRates()
        await this.setGregorianToHijri(this.dateToConvert)
        this.islamicDate = this.gregorianToHijri.data.hijri
      }
    },
    displayDate (val) {
      return DateTime.fromMillis(parseInt(val)).toFormat('yyyy-MM-dd')
    },
    getRates () {
      this.setExchangeRate(this.selectedDate)
      this.setGoldSpotPricesPerOunce(this.selectedDate)
      this.setSilverSpotPricesPerOunce(this.selectedDate)
      this.setGoldSpotPricesPerGram(this.selectedDate)
      this.setSilverSpotPricesPerGram(this.selectedDate)
    },
    openAlert (title, message, align = 'center') {
      this.alert = {
        title: title,
        message: message,
        align: align
      }
      document.body.style.overflow = 'hidden'
    },
    closeAlert () {
      this.alert = null
      document.body.style.overflow = ''
    }
  },
  computed: {
    ...mapGetters([
      'exchangeRate',
      'goldRatePerOunce',
      'silverRatePerOunce',
      'goldRatePerGram',
      'silverRatePerGram',
      'gregorianToHijri'
    ]),
    islamicDateFormat () {
      if (!this.islamicDate) return ''
      return this.islamicDate.day + ' ' + this.islamicDate.month.en + ' ' + this.islamicDate.year
    },
    islamicDateSaveFormat () {
      if (!this.islamicDate) return ''
      return this.islamicDate.year + '-' + (this.islamicDate.month.number < 10 ? '0' + this.islamicDate.month.number : this.islamicDate.month.number) + '-' + this.islamicDate.day
    }
  }

}
</script>

<style scoped>
.disclaimer {
  font-size: 0.7rem;
}
</style>
